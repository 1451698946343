import * as React from "react";
import EventList from "../components/ui/Events/Events";
import { graphql } from "gatsby";
import { EventQuery } from "../fragments/NodeEventFields";
import PageHeader from "../components/ui/PageHeader";
import { RegionalEngineerFields } from "../fragments/NodeRegionalEngineerFields";
import DipraBreadcrumbs from "../components/Layout/Breadcrumbs";
import { Img, WmkImage } from "wmk-image";
import styled from "styled-components";
import { FaRegCalendarAlt } from "react-icons/fa";
import { WmkLink } from "wmk-link";
import DipraButton from "../components/ui/DipraButton";
import { colors } from "../vars/palette";
import { Col, Container, Row } from "react-bootstrap";

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;

  .image-div {
    background: ${colors.blue.hex};
    max-width: 160px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const ButtonContainer = styled(Container)``;

const regionalEngineerEventsPageNode = ({
  pageContext,
  data
}: {
  pageContext: { region: string };
  data: { events: { edges: EventQuery[] }; engineer: RegionalEngineerFields };
}) => {
  // console.log("data", data);
  const adjustedTitle = data?.engineer?.region.split(": ");
  const regionDisplayTitle = data?.engineer?.region.includes("Canada")
    ? adjustedTitle[1]
    : `${adjustedTitle[1]} States`;
  // console.log("adjustedTitle", adjustedTitle);
  return (
    <>
      <DipraBreadcrumbs
        route={`news/upcoming-events/${adjustedTitle[1].toLowerCase()}`}
      />
      <ImageWrapper>
        <div className="image-div">
          <WmkImage image={new Img(data?.engineer?.higherQualityImg)} />
        </div>
      </ImageWrapper>
      <PageHeader
        headline={regionDisplayTitle}
        subhead={data?.engineer?.name}
        // subtext={data?.engineer?.bioText?.bioText}
      />
      <ButtonContainer>
        <Row>
          <Col>
            <ButtonWrapper>
              <DipraButton
                color={colors.green.hex}
                link={{
                  text: `Contact a Regional Engineer`,
                  to: `/ask-an-expert/contact-a-regional-engineer`
                }}
                textColor={colors.white.hex}
                style={{
                  fontSize: `1rem`,
                  // width: "100%",
                  transition: `all .3s ease`,
                  textTransform: "uppercase",
                  padding: "0.75rem 0rem"
                }}
                hoverColor={colors.blue.hex}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <DipraButton
                color={colors.green.hex}
                link={{
                  text: `All DIPRA Events`,
                  to: `/ask-an-expert/where-dipra-will-be`
                }}
                textColor={colors.white.hex}
                style={{
                  fontSize: `1rem`,
                  // width: "100%",
                  transition: `all .3s ease`,
                  textTransform: "uppercase",
                  padding: "0.75rem 0rem"
                }}
                hoverColor={colors.blue.hex}
              />
            </ButtonWrapper>
          </Col>
        </Row>
      </ButtonContainer>
      <EventList data={data} />
    </>
  );
};

export default regionalEngineerEventsPageNode;

export const query = graphql`
  query regionalEngineerEventsPageQuery($region: String) {
    events: allContentfulEvents(
      sort: { fields: startDate, order: ASC }
      filter: { region: { in: [$region, "All Regions"] }, title: {ne: "schema"} }
    ) {
      edges {
        node {
          ...NodeEventFields
        }
      }
    }
    engineer: contentfulEngineer(region: { eq: $region }) {
      ...NodeRegionalEngineerFields
    }
  }
`;
