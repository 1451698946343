import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../../vars/palette";
import { EventQuery } from "../../../fragments/NodeEventFields";

export const getTableBg = (index: number) =>
  index % 2
    ? `rgba(${colors.lightGreen.r},${colors.lightGreen.g},${colors.lightGreen.b},0.1)`
    : `rgba(${colors.blue.r},${colors.blue.g},${colors.blue.b},0.1)`;

export const TableRowStyles = styled(Row)`
  a {
    color: ${colors.darkBlue.hex};
    &:hover {
      color: ${colors.text.hex};
    }
  }
  font-size: 0.8rem;
  padding: 0.5vh 0;
  background: ${({ index }) => getTableBg(index)};
  @media only screen and (max-width: 350px) {
    font-size: 0.6rem;
  }
`;

const HeadingWrap = styled(Row)`
  padding: 0.75vh 0;
  background: ${`rgba(${colors.blue.r},${colors.blue.g},${colors.blue.b},0.4)`};
`;

const Heading = styled.strong`
  color: ${colors.text.hex};
`;

/**
 * an array of strings (Table column titles)
 * @returns Table header with variable amount of column headings
 */
export const TableHeading = ({ headings }: { headings: string[] }) => {
  return Array.isArray(headings) && headings.length ? (
    <HeadingWrap>
      {headings.map((columnHeader, i) => {
        return i === 0 ? (
          <ColFirst key={columnHeader + i}>
            <Heading>{columnHeader}</Heading>
          </ColFirst>
        ) : (
          <ColMid key={columnHeader + i}>
            <Heading>{columnHeader}</Heading>
          </ColMid>
        );
      })}
    </HeadingWrap>
  ) : null;
};

const EventList = ({ data }: { data: { events: { edges: EventQuery[] } } }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     events: allContentfulEvents(sort: { fields: startDate, order: ASC }) {
  //       edges {
  //         node {
  //           ...NodeEventFields
  //         }
  //       }
  //     }
  //   }
  // `);
  const events = data
    ? data.events.edges.map((e: { node: EventQuery }) => e.node)
    : [];

  return (
    <Container
      style={{
        marginTop: "2rem",
        marginBottom: "4rem"
      }}>
      <TableHeading
        headings={["Event", "Location", "Dates", "DIPRA Presentations"]}
      />
      {Array.isArray(events) &&
        events.map((event, i) => {
          // date formatting
          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ];
          const start = new Date(event.startDate);
          const startMonth = months[start.getUTCMonth()];
          const startDate = start.getUTCDate();
          const end = new Date(event.endDate);
          const endMonth = months[end.getUTCMonth()];
          const endDate = end.getUTCDate();
          const year = end.getUTCFullYear();
          const when =
            startMonth === endMonth && startDate === endDate
              ? `${startMonth} ${startDate}, ${year}`
              : `${startMonth} ${startDate} - ${endMonth} ${endDate}, ${year}`;

          const show = true;
          const what = event.title;
          const where = event.location;
          const presentations = event?.dipraPresentations;
          // const { show, what, where, when, booth } = event;
          return (
            <Event
              key={"event" + i}
              show={show}
              what={what}
              where={where}
              when={when}
              pres={presentations}
              index={i}
            />
          );
        })}
    </Container>
  );
};

export default EventList;

export const ColFirst = ({ children, xs = 3, sm = 5 }) => {
  return (
    <Col
      xs={xs}
      sm={sm}
      md="5"
      lg="5"
      style={{ display: "flex", alignItems: "center" }}>
      {children}
    </Col>
  );
};

export const ColMid = ({ children, xs = 3, sm = 2 }) => {
  return (
    <Col
      xs={xs}
      sm={sm}
      md="2"
      lg="2"
      style={{ display: "flex", alignItems: "center" }}>
      {children}
    </Col>
  );
};

const ColLast = ({ children }) => {
  return <Col xs="3">{children}</Col>;
};

const Event = ({
  index,
  show,
  what,
  where,
  when,
  pres
}: {
  index: number;
  show: boolean;
  what: string;
  where: string;
  when: string;
  pres: string | null;
}) => {
  return show ? (
    <TableRowStyles index={index}>
      <ColFirst>{what}</ColFirst>
      <ColMid>
        {where && where.toLowerCase() === "virtual" ? (
          <strong>Virtual</strong>
        ) : (
          where
        )}
      </ColMid>
      <ColMid>{when}</ColMid>
      <ColLast>{pres}</ColLast>
    </TableRowStyles>
  ) : null;
};
