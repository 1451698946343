import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { searchResult } from "../components/util/searchResultInterface";
import { RichTextFields } from "../classes/RichText";
import { RegionalEngineerFields } from "../fragments/NodeRegionalEngineerFields";
import { MemberCompanyFields } from "../fragments/NodeMemberCompanyFields";
import { StaffAssetQuery } from "../fragments/NodeStaffAssetFields";
import { AllianceFields } from "../fragments/NodeAllianceFields";
import { EventQuery } from "../fragments/NodeEventFields";
import { BlockIconCopyFields } from "../fragments/NodeRichTextFields";
import { getDocument } from "pdfjs-dist";
import {
  DocumentInitParameters,
  PDFDataRangeTransport,
  TypedArray
} from "pdfjs-dist";

const pdfjs = await import("pdfjs-dist/build/pdf");
const pdfjsWorker = await import("pdfjs-dist/build/pdf.worker.entry");
import { useState, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { CalculatorButton } from "../components/calculators/LoadAndSave";
import { Type } from "../components/ui/Typography";

// import { Document } from "../classes/Document";
// import { Pages } from "../classes/Page";
// import { Resources } from "../classes/Resource";

// import { DocumentFields } from "../fragments/NodeDocumentFields";
// import { PageFields } from "../fragments/NodePageFields";
// import { ResourceQuery } from "../fragments/NodeResourceFields";

/*interface SearchPageQuery {
    document: {
        edges: {
            node: DocumentFields;
        }[];
    };
    pageAsset: {
        edges: {
            node: PageFields;
        }[];
    };
    resourceAsset: {
        edges: {
            node: ResourceQuery;
        }[];
    };
}*/

interface SearchPageQuery {
  allContentfulPage: {
    edges: {
      node: {
        id: string;
        headline: string;
        subtext: { subtext: string };
        title: string;
        slug: string;
        parentPath: string;
        metaDescription: {
          metaDescription: string;
        };
        content: RichTextFields;
      };
    }[];
  };
  allContentfulDocument: {
    edges: {
      node: {
        id: string;
        keywords: string[];
        slug: string;
        title: string;
        shelfSlugs: {
          shelfSlugs: string;
        };
        file: {
          description: string;
          url: string;
        };
      };
    }[];
  };
  allContentfulResource: {
    edges: {
      node: {
        id: string;
        slug: string;
        title: string;
        serpTitle: string;
        parentPath: string;
        metaDescription: {
          metaDescription: string;
        };
        content: RichTextFields;
      };
    }[];
  };
  allContentfulEngineer: { edges: RegionalEngineerFields[] };
  allContentfulMemberCompany: { edges: MemberCompanyFields[] };
  allContentfulStaff: { edges: StaffAssetQuery[] };
  allContentfulAlliance: { edges: AllianceFields[] };
  allContentfulEvents: { edges: EventQuery[] };
  vBio: BlockIconCopyFields;
}

const getPdfText = async (
  src: string | TypedArray | DocumentInitParameters | PDFDataRangeTransport
): Promise<string> => {
  // console.log("src", src)
  let pdf;
  try {
    pdf = await getDocument(src).promise;
  } catch (error) {
    console.log("error1", error);
  }
  // console.log("pdf", pdf)
  if (pdf) {
    let pageList;
    try {
      pageList = await Promise.all(
        Array.from({ length: pdf.numPages }, (_, i) => pdf.getPage(i + 1))
      );
    } catch (error) {
      console.log("error2", error);
    }
    // console.log("pageList", pageList)
    let textList;
    try {
      textList = await Promise.all(pageList.map((p) => p.getTextContent()));
    } catch (error) {
      console.log("error3", error);
    }
    const combinedString = textList
      .map(({ items }) => items.map(({ str }) => str).join(" "))
      .join(" ");
    const blob = new Blob([combinedString]);
    if (blob.size > 100000) {
      const shortentedString = combinedString.slice(0, 90500);
      return shortentedString;
    } else {
      return combinedString;
    }
  } else {
    return "";
  }
  // console.log("textList", textList)
};

const SearchAssetContent = () => {
  const data: SearchPageQuery = useStaticQuery(query);
  //const documents = new Document(data.document.edges);
  //const pages = new Pages(data.pageAsset.edges);
  //const resources = new Resources(data.resourceAsset.edges);
  //const pdfs = new PdfAssets(data.PdfAsset.edges);

  // console.log("data", data);

  //document extraction
  const [bigData, setBigData] = useState();
  let allData: searchResult[] = [];

  const [documentData, setDocumentData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [buildIndex, setBuildIndex] = useState(false);
  const [indexBuildRan, setIndexBuildRan] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const [pass, setPass] = useState("");
  const [error, setError] = useState(false);

  const pw = "watermark";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pass === pw) {
      setError(false);
      setIsLocked(false);
      if (buildIndex === false) {
        setBuildIndex("True");
      }
    } else {
      setError(true);
      setPass("");
    }
  };

  const handleChange = (e) => {
    const p = e.target.value;
    setPass(() => {
      if (p === "") {
        setError(false);
      }
      return p;
    });
  };

  // useEffect(()=> {console.log("documentData", documentData)}, [documentData])

  useEffect(() => {
    const getData = async () => {
      const combinedData = await Promise.all(
        data.allContentfulDocument.edges.map(async (value) => {
          const object = async () => {
            const text = getPdfText(value.node.file.url);

            return {
              objectID: value.node.id,
              node: {
                title: value.node.title,
                slug: value.node.slug,
                metaDescription: value.node?.file?.description,
                keywords: value.node.keywords,
                _tags: value.node.shelfSlugs.shelfSlugs.split(", "),
                text: await text
              },
              nodeType: "doc"
            };
          };
          return await object();
        })
      );
      setDocumentData(await combinedData);
    };
    getData();
  }, []);

  // data.allContentfulDocument.edges.forEach(function (value) {
  //   // const text = getPdfText(value.node.file.url);
  //   // console.log("value.node.title", value.node.title)
  //   allData.push({
  //     objectID: value.node.id,
  //     node: {
  //       title: value.node.title,
  //       slug: value.node.slug,
  //       metaDescription: value.node?.file?.description,
  //       keywords: value.node.keywords,
  //       _tags: value.node.shelfSlugs.shelfSlugs.split(", "),
  //       // text: getPdfText(value.node.file.url)
  //     },
  //     nodeType: "doc"
  //   });
  // });

  //pages extraction
  let pages = data.allContentfulPage.edges.map(function (value) {
    let searchTerm = "";
    searchTerm = value.node.title;
    // if (value.node.headline == null) {
    // } else {
    //   searchTerm = value.node.title + " " + value.node.headline;
    // }
    return {
      objectID: value.node.id,
      node: {
        title: searchTerm,
        slug: value.node.slug,
        subtext:
          searchTerm === "DIPRA's E-newsletters" || searchTerm === "Subscribe"
            ? value.node?.subtext?.subtext.concat(
                " Subscribe to DIPRA's E-Newsletters"
              )
            : value.node?.subtext?.subtext,
        metaDescription: value.node?.metaDescription?.metaDescription,
        parentPath: value.node.parentPath,
        content: value.node?.content,
        engineers:
          data.allContentfulEngineer &&
          searchTerm === "Contact a Regional Engineer"
            ? data.allContentfulEngineer
            : [],
        memberCompanies:
          data.allContentfulMemberCompany && searchTerm === "Member Companies"
            ? data.allContentfulMemberCompany
            : [],
        staff:
          data.allContentfulStaff && searchTerm === "DIPRA Staff"
            ? data.allContentfulStaff
            : [],
        alliances:
          data.allContentfulAlliance && searchTerm === "Alliances"
            ? data.allContentfulAlliance
            : [],
        events:
          data.allContentfulEvents &&
          (searchTerm === "Where We'll Be" || searchTerm === "Upcoming Events")
            ? data.allContentfulEvents
            : []
      },
      nodeType: "page"
    };
  });
  
  // useEffect(() => {
  //   console.log("pageData", pageData);
  // }, [pageData]);

  pages.push({
    objectID: "Ask An Engineer",
    node: {
      title: "Ask An Engineer",
      slug: "ask-an-engineer",
      subtext: {
        subtext: `DIPRA's engineers are glad to answer your questions about the use and specification of Ductile Iron Pipe. Please submit your question using the following form. Your DIPRA engineer will respond shortly.`
      },
      parentPath: "/ask-an-expert"
    },
    nodeType: "page"
  });

  pages.push({
    objectID: "All Technical Resources",
    node: {
      title: "All DIPRA Resources",
      slug: "all",
      subtext: {
        subtext: `Click on any link in the below list to access technical resources in downloadable PDF format, or launch an informative video.`
      },
      parentPath: "/technical-resource"
    },
    nodeType: "page"
  });

  useEffect(() => {
    setPageData(pages);
  }, []);

  //resource extraction
  let resources = data.allContentfulResource.edges.map(function (value) {
    return {
      objectID: value.node.id,
      node: {
        origTitle: value.node.title,
        title: value.node.serpTitle ? value.node.serpTitle : value.node.title ,
        slug: value.node.slug,
        metaDescription: value.node?.metaDescription?.metaDescription,
        parentPath: value.node.parentPath,
        content: value.node?.content
      },
      nodeType: "resource"
    };
  });

  resources.push({
    objectID: "V-Bio Patent",
    node: {
      origTitle: "V-Bio Patent",
      title: "V-Bio Patent",
      slug: "patent",
      metaDescription:
        "Prevent corrosion on ductile iron pipes with our V-Bio® Enhanced Polyethylene Encasement and other effective methods.",
      parentPath: "/V-Bio",
      content: data.vBio
    },
    nodeType: "resource"
  });
  useEffect(() => {
    setResourceData(resources);
  }, []);

  useEffect(() => {
    if ((resourceData, pageData, documentData)) {
      // console.log("documentData", documentData);
      setBigData(resourceData.concat(pageData).concat(documentData));
    }
  }, [documentData]);
  // useEffect(() => {
  //   console.log("bigData", bigData);
  // }, [bigData]);

  // console.log(allData);

  // init algolia
  const algoliasearch = require("algoliasearch");

  // Connect and authenticate with your Algolia app
  const client = algoliasearch(
    "ODQJXR6FON",
    "0e406faab78dc1d9c33a231cc3c27f2f"
  );

  // Create a new index and add a record
  const index = client.initIndex("dipra_source_index");
  // console.log("bigData pre go", bigData);
  
  useEffect(() => {
    if (documentData && bigData && bigData !== [] && buildIndex === "True") {

      index.clearObjects().then(() => {
        index
          .saveObjects(bigData, { autoGenerateObjectIDIfNotExist: false })
          .then(({ objectIDs }: { objectIDs: string[] }) => {
            console.log("success");
          })
          .then(setBuildIndex(false))
          .then(setIndexBuildRan(true));
      });
    }
  }, [bigData, buildIndex]);

  const handleBuildIndex = () => {
    if (buildIndex === false) {
      setBuildIndex("True");
    }
  };

  const getDisplay = () => {
    if (!documentData.length) {
      return <p>please wait for the data to load</p>;
    } else if (documentData && documentData?.length && !indexBuildRan) {
      return (
        <div style={{ maxWidth: "60vw", margin: "0 auto", width: `100%` }}>
        <Form onSubmit={handleSubmit} style={{ margin: 0 }}>
          <Form.Label>Enter Password to Rebuild Index</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={pass}
            onChange={handleChange}
            autoComplete={"current-password"}
          />
          <CalculatorButton type="submit">Rebuild</CalculatorButton>
        </Form>
        {error ? (
          <Card
            bg={"warning"}
            style={{
              display: `flex`,
              justifyContent: "center",
              alignItems: "center"
            }}>
            <Type.P style={{ padding: `1rem 1rem 0 1rem` }}>
              Invalid password. Please try again.
            </Type.P>
          </Card>
        ) : null}
      </div>
      );
    } else {
      return <p>Index successfully rebuilt</p>;
    }
  };

  // Search the index and print the results
  //index.search('entity').then(({hits}) => console.log(hits[0]))

  /*const AlgoliaApiContent = () => {
        //const data: ConferencePageQuery = useStaticQuery(query);
    };*/
    

  return (
    <>
      {!bigData && getDisplay()}
      {bigData && !indexBuildRan && getDisplay()}
      {bigData && indexBuildRan && getDisplay()}
    </>
  );
};

export default SearchAssetContent;

const query = graphql`
  query {
    allContentfulPage(
      filter: {
        title: {
          nin: [
            "Thank You - Ask An Engineer"
            "Thank You - DIPRA E-Newsletters CTA"
            "Thank You - DIPRA E-Newsletters Form"
            "Thank You - Support The Issues"
            "Thank You - Order Installation Guide"
            "algoapi"
            "Schema"
            "Resource Shelf"
            "Logo & Tagline"
            "Conference"
            "Test"
            "Digital Ad Bank 2022"
            "Print Ad Bank 2022"
            "Resource Shelf"
          ]
        }
      }
    ) {
      edges {
        node {
          id
          headline
          subtext {
            subtext
          }
          title
          slug
          parentPath
          metaDescription {
            metaDescription
          }
          content {
            raw
            references {
              ... on ContentfulBlockImageCopy {
                copy {
                  copy
                }
              }
              ... on ContentfulBlockIconCopy {
                headline
                copy {
                  copy
                }
              }
              ... on ContentfulBlockIconCard {
                copy {
                  copy
                }
              }
            }
          }
        }
      }
    }
    allContentfulDocument(
      filter: {
        id: {
          nin: [
            "288d134e-5c2d-58b0-a5f9-279a5174519b"
            "8723ba4d-90a0-5b8e-8a28-752ecc138c92"
            "fb94c673-f41f-542e-9509-70544c68f10e"
            "f8674af5-48d7-542a-86c1-f6745063289c"
            "41bb9e75-1e77-5d03-86b0-3dae23e9ec69"
            "58d45373-1daa-5c38-a5dc-a6e83c6b2627"
            "e0665dad-a895-594a-bcbf-d1571e4fbedd"
            "3f7b59be-7bbf-5a3a-a4fe-85d88ec7b7cf"
            "6adcd4c8-e126-58da-acae-fb094448ca1a"
            "3d5993ee-7e60-5466-a79f-4ba61db71be6"
            "60473012-f67a-5d55-bcee-4a58e6081b89"
            "f0ce1039-de8d-5a77-ae0d-93bacd08d36f"
            "00d4d28f-e678-58a0-8cfa-cc053aee5ae3"
            "9a9803cd-344e-5cae-9e1e-08b335da5c2c"
            "8c1d44dc-14ba-516b-837d-5ed4c3d2d5b6"
            "1e154172-2a53-5e52-a138-54ce64bca751"
            "02a1165d-a7f2-5c01-a25e-05537d83cce9"
            "c69aca3d-9059-5cfa-9e88-85fa111b1556"
            "c333df1e-14b7-5125-a890-94083bf491ed"
          ]
        }
      }
    ) {
      edges {
        node {
          id
          keywords
          slug
          title
          shelfSlugs {
            shelfSlugs
          }
          file {
            description
            url
          }
        }
      }
    }
    allContentfulResource(filter: { title: { nin: ["Schema"] } }) {
      edges {
        node {
          id
          slug
          title
          serpTitle
          parentPath
          metaDescription {
            metaDescription
          }
          subtext {
            subtext
          }
          content {
            raw
            references {
              ... on ContentfulBlockIconCopy {
                copy {
                  copy
                }
              }
              ... on ContentfulBlockImageCopy {
                copy {
                  copy
                }
              }
              ... on ContentfulBlockTestimonial {
                testimonial {
                  testimonial
                }
              }
            }
          }
        }
      }
    }
    allContentfulEngineer {
      edges {
        node {
          ...NodeRegionalEngineerFields
        }
      }
    }
    allContentfulMemberCompany(sort: { fields: title }) {
      edges {
        node {
          ...NodeMemberCompanyFields
        }
      }
    }
    allContentfulStaff(sort: { order: ASC, fields: order }) {
      edges {
        node {
          ...NodeStaffFields
        }
      }
    }
    allContentfulAlliance(sort: { order: ASC, fields: title }) {
      edges {
        node {
          ...NodeAllianceFields
        }
      }
    }
    allContentfulEvents(sort: { fields: startDate, order: ASC }) {
      edges {
        node {
          ...NodeEventFields
        }
      }
    }
    vBio: contentfulBlockIconCopy(
      blockId: {
        eq: "Technical Resources>Design Steps>Installation>Encasements>V-Bio® Patents"
      }
    ) {
      ...NodeBlockIconCopy
    }
  }
`;
