import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
//mport { HiShare } from "react-icons/hi";
import { FaFacebook, FaLinkedin, FaTwitter, FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from "react-share";
import { colors } from "../../../../vars/palette";
import { Type } from "../../../ui/Typography";
import { Col, Container, Row } from "react-bootstrap";
import { IoClose } from "react-icons/io5";

const Wrap = styled.div`
  @media print {
    display: none;
  }
  position: relative;
  button {
    background: white;
    border: none;
    padding: 10px 10px;
    font-size: 30px;
  }
`;
const Drawer = styled.div`
  position: absolute;
  z-index: ${({ open }) => (open ? "1" : "-10000")};
  // height: ${({ open }) => (open ? "auto" : "0")};
  // width: ${({ open }) => (open ? "12rem !important" : "0 !important")};
  width: 12rem !important;
  bottom: ${({ open }) => (open ? "-80px" : "1000px")};
  left: -4rem;
  transition: all 0.3s ease;
  background: ${colors.white.hex};
  color: ${colors.green.hex};
  font-size: 2rem;
  text-align: center;
  border-radius: 0 0 5px 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  .row {
    margin: 0 !important;
  }
  button {
    margin: 0 0.5rem;
  }
  width: 100%;
`;
const IconsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ToggleClose = styled.div`
  position: absolute;
  z-index: 20;
  top: 2px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  :hover {
    cursor: pointer;
  }
`;

const CustomShareIcon = ({ height = 26, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width ? width : height}
      viewBox="0 0 47 47">
      <g transform="translate(0)">
        <path
          d="M40.607,32.591a7.764,7.764,0,0,0-6.9,3.481l-12.65-5.5a11.871,11.871,0,0,0,.072-9.736L33.171,15.9a7.86,7.86,0,0,0,7.436,4.509c11.191,0,11.191-17.543,0-17.4a7.919,7.919,0,0,0-6.338,2.725,8.56,8.56,0,0,0-2.015,6.8L19.121,17.916a9.667,9.667,0,0,0-7.05-2.729c-13.428,0-13.428,20.885,0,20.885a9.7,9.7,0,0,0,6.939-2.611l13.4,5.827c-.972,4.9,1.754,10.707,8.195,10.707C51.8,49.995,51.8,32.451,40.607,32.591Zm0-26.106a5.131,5.131,0,0,1,5.036,5.221,5.039,5.039,0,1,1-10.071,0A5.131,5.131,0,0,1,40.607,6.485ZM12.071,32.591a6.966,6.966,0,0,1,0-13.923,6.966,6.966,0,0,1,0,13.923ZM40.607,46.514a5.225,5.225,0,1,1,5.036-5.221,5.131,5.131,0,0,1-5.036,5.221Z"
          transform="translate(-2 -2.995)"
          fill="#394a58"
        />
      </g>
    </svg>
  );
};

const Share = ({ toggle, open, shareUrl }) => {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    const windowUrl = window.location.href;
    if (shareUrl) {
      setUrl(shareUrl);
    } else if (windowUrl) {
      setUrl(windowUrl);
    } else {
      setUrl("https://dipra.org");
    }
  }, [url, open, shareUrl]);
  return (
    <>
      <Wrap>
        <button
          onClick={toggle}
          className="socialItems"
          name="share"
          htmlFor="share"
          aria-label="share this page">
          <CustomShareIcon />
        </button>
        <Drawer open={open}>
          <Container>
            <Row style={{ alignItems: "flex-end", width: "100%" }}>
              <Col></Col>
              <Col xs="auto">
                <div style={{ background: colors.white.hex }}>
                  <Type.EyebrowTitle
                    style={{
                      textTransform: "uppercase",
                      margin: `1rem 0 0 0`,
                      whiteSpace: `nowrap`
                    }}>
                    Share this page
                  </Type.EyebrowTitle>
                  <IconsWrap>
                    <FacebookShareButton url={url}>
                      <FaFacebook />
                    </FacebookShareButton>
                    <LinkedinShareButton url={url}>
                      <FaLinkedin />
                    </LinkedinShareButton>
                    <TwitterShareButton url={url}>
                      <FaXTwitter />
                    </TwitterShareButton>
                    <EmailShareButton url={url}>
                      <FaEnvelope />
                    </EmailShareButton>
                  </IconsWrap>
                </div>
              </Col>
            </Row>
          </Container>
          <ToggleClose onClick={() => toggle()}>
            <IoClose size="18px" color="#808080" />
          </ToggleClose>
        </Drawer>
      </Wrap>
    </>
  );
};

export default Share;
