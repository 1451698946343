import * as React from "react";
import { WmkSeo } from "wmk-seo";
import { graphql } from "gatsby";
import PageHero from "../components/ui/PageHero";
import { Page } from "../classes/Page";
import { RichTextReact } from "wmk-rich-text";
import CisionNewsSlider from "../components/ui/News/NewsSliders/CisionNewsSlider";
import HowCanWeHelpSlider from "../components/ui/Home/HowCanWeHelp";
import NewsletterSignupCTA from "../components/ui/News/NewsletterSignupCTA";
import { MetadataFields } from "../fragments/NodeMetaFields";
import { PageFields } from "../fragments/NodePageFields";
import { options } from "../components/ui/RichText/richTextOptions";
import { Container } from "react-bootstrap";
import { Type } from "../components/ui/Typography";
import { colors } from "../vars/palette";
import DipraNewsSlider from "../components/ui/News/NewsSliders/DipraNewsSlider";

const IndexPage = ({ data }: IndexPageQuery) => {
  const { siteMetadata } = data.site;
  const home = new Page(data.home);
  return (
    <>
      <WmkSeo.Meta
        siteTitle={siteMetadata.title}
        title={siteMetadata.description}
        slug="/"
        baseUrl={siteMetadata.baseUrl}
        twitterImageUrl={home.image.url("twitterImage")}
        ogImageUrl={home.image.url("ogImage")}
        twitterHandle={siteMetadata.twitter}
        description={home.meta}
      />
      <PageHero
        arrowUrl={home.featuredImage.src}
        headline={home.intro.headline}
        subhead={home.intro.subhead}
        text={home.intro.subtext}
      />
      <HowCanWeHelpSlider />
      <RichTextReact content={home.content} options={options} />
      {/* <CisionNewsSlider title="In The News" /> */}
      <Container>
        <div>
          <Type.H3
            style={{
              color: colors.blue.hex,
              fontSize: "2rem",
              textAlign: "center",
              textTransform: "none",
              fontWeight: "bold",
              marginTop: "2rem",
              marginBottom: "-5rem"
            }}>
            {"In The News"}
          </Type.H3>
          <DipraNewsSlider />
        </div>
      </Container>
      <NewsletterSignupCTA />
    </>
  );
};

export default IndexPage;

export interface IndexPageQuery {
  data: {
    site: MetadataFields;
    home: PageFields;
  };
}

export const query = graphql`
  {
    site {
      ...SiteMetadataFields
    }
    home: contentfulPage(slug: { eq: "home" }) {
      ...NodePageFields
      ...PageBlockImageCopy
      ...PageBlockIconCopy
      ...PageBlockIconCard
      ...PageBlockTestimonial
      ...PageBlockColumns
    }
  }
`;
